import logo from './img/glossglaze_logo.svg';


const Landing = () => {
  return (
    <div className="app">
      <header className="header">
        <img className="logo" src={logo} alt="logo"/>
        <h2>Discover, collect, and sell kinky NFTs</h2>
        <h3>Gloss & Glaze is the world's first kinky NFT marketplace. Support your favorite alternative models and artists.</h3>
      </header>
    </div>
  );
}

export default Landing
