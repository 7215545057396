import { useQuery, useMutation } from 'graphql-hooks';
import { NavLink, useParams, useLocation } from 'react-router-dom';
import { FOLLOWERS, FOLLOWING, FOLLOW } from 'utils/queries';

import Tab from 'common/Tab';
import List from 'common/List';

const Follow = _ => {
  const { username } = useParams();
  const { pathname } = useLocation();
  const query = pathname.split("/").pop() === 'followers' ? FOLLOWERS : FOLLOWING;

  const { data, refetch } = useQuery(query, { variables: { username } });
  const [follow] = useMutation(FOLLOW);

  return (
    <>
    <Tab>
      <NavLink to="../followers">Followers</NavLink>
      <NavLink to="../following">Following</NavLink>
    </Tab>
    <List {...{ data: data?.getFollowers?.map(i => ({
      link: i.username, thumb: i.avatar, title: i.username, control: i.relationship ? {
        label: i.relationship,
        action: () => follow({ variables: { id: i.id } }),
        refetch
      } : null
    })) || data?.getFollowing?.map(i => ({
      link: i.username, thumb: i.avatar, title: i.username,  control: i.relationship ? {
        label: i.relationship,
        action: () => follow({ variables: { id: i.id } }),
        refetch
      } : null
    }))  || []} }/>
    </>
  )
}

export default Follow;
