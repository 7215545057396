import style from 'token/Token.module.css';

import { useNavigate } from 'react-router-dom';
import { useQuery } from 'graphql-hooks';

import { ASSET } from 'utils/queries';
import Loader from 'common/Loader';


const Token = ({ id }) => {
  const navigate = useNavigate();

  const { data, loading } = useQuery(ASSET, { variables: { id }})
  const { asset } = data || {};

  const click = _ => {
    navigate(`/token/${asset?.asset}`);
  }
  const meta = JSON.parse(asset?.metadata || "[]");
  const url = `${process.env.REACT_APP_IPFS}/${meta?.image?.replace('ipfs://','')}`;

  if(loading) return <Loader/>

  return (
    <div className={style.card} onClick={click}>
      <div className={style.image}>
        { meta.image && <img src={url} alt={meta.image}/>  }

      </div>
      <div className={style.meta}>
        <div className={style.title}>{meta.name}</div>
      </div>
    </div>
  )
}

export default Token;
