import style from 'token/Create.module.css';

import { useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from 'formik';
import { useDropzone } from 'react-dropzone'
import { useMutation } from 'graphql-hooks';

import { useSession } from 'utils/useSession';
import { createPolicy, mintTx, signTx } from 'utils/cardano';
import { isAlphaNumericDashSpace } from 'utils/validate';
import { UPLOAD, MINT } from 'utils/queries';
import Input from 'common/Input';
import Textarea from 'common/Textarea';
import Button from 'common/Button';
//import {Label, Infinity, Timer} from 'common/Icons.jsx';

const Create = _ => {
  const [preview, setPreview] = useState(null);
  const [image, setImage] = useState(null);
  const [imageError, setImageError] = useState(null);
  const [fee, setFee] = useState(0);
  const { user } = useSession();

  const navigate = useNavigate();
  const [upload] = useMutation(UPLOAD);
  const [mint] = useMutation(MINT);


  const onDrop = useCallback(async ([file]) => {
    setImage(file);
    setPreview(URL.createObjectURL(file));
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({ onDrop, accept: 'image/*', maxFiles: 1 });

  const initialValues = {
    name: '',
    description: '',
    photographer: '',
    price: 0,
  };

  const calculateFee = price => {
    setFee(price*0.975);
  }

  const validate = values => {
    const errors = {};

    if(!isAlphaNumericDashSpace.test(values.name) && values.name === '') {
      errors.name = 'Invalid name use only a-Z, 0-9, .-_ ';
    }
    if(values.description === '') {
      errors.description = 'Invalid description';
    }
    if(values.description.length > 64) {
      errors.description = 'Description exceeding 64 characters';
    }
    if(!isAlphaNumericDashSpace.test(values.photographer) && values.photographer === '') {
      errors.photographer = 'Invalid photographer use only a-Z, 0-9, .-_';
    }
    if(values.price === 0) {
      errors.price = 'Please set a price';
    }

    if(!image) {
      setImageError(true);
      errors.image = 'Please provide an image';
    } else  {
      delete errors.image;
      setImageError(false)
    }

    calculateFee(values.price);

    return errors;
  };

  const onSubmit = async values => {
    // generate token name
    const tokenName = `${user.username}.${values.name.replace(/\s/g, '')}`;

    // upload image to ipfs
    const { data } = await upload({ variables: { image, tokenName } });
    if(!data?.upload) return console.log('error uploading asset');

    // generate policy
    const { address } = user;
    const policy = createPolicy({address});

    // generate asset name
    const { name, description, photographer, price } = values;
    const metadata = { name, description, photographer,
      creator: user.username,
      image: `ipfs://${data.upload}`,
      site: 'https://www.glossglaze.com/',
    }

    const utxos = await window.cardano.getUtxos();

    const tx = mintTx({address, tokenName, policy, metadata, utxos});
    const witnesses = await window.cardano.signTx(tx);
    const signedTx = await signTx({tx, witnesses});
    const txHash = await window.cardano.submitTx(signedTx);

    console.log('mint tx:', txHash);

    // save in server
    await mint({
      variables: {
        policyId: policy.id,
        tokenName,
        metadata: JSON.stringify(metadata),
        price
      }
    }).catch(e => console.log(e));

    navigate(`/${user.username || user.id}`);
  };

  return (
    <div className={style.edit}>
      <h1>Create single item on Cardano</h1>
      <p>Upload your NFT and set it for sale.</p>
      <div className={style.column}>

        <div {...getRootProps({className: style.dropzone})}>
          <input {...getInputProps()} />
          {
            preview ? <img className={style.preview} src={preview} alt="profile"/> :
            isDragActive ?
              <p>Drop your NFT image here ...</p> :
              <p className={imageError && style.imageError}>Drag 'n' drop your image here, or click to select image</p>
          }
        </div>

        <Formik {...{ initialValues, validate, onSubmit}} enableReinitialize>
           {({ isSubmitting }) => (
             <Form className={style.form}>
               <Field {...{ name: 'name', type: 'Text', label: 'Name', placeholder: 'Enter token name', as: Input }} />
               <Field {...{ name: 'description', type: 'textarea', label: 'Description', placeholder: 'Write a caption...', as: Textarea }} />
               <Field {...{ name: 'photographer', type: 'Text', label: 'Photographer', placeholder: 'Photographer name', as: Input }} />
               <Field {...{ name: 'price', type: 'Number', label: 'Price', pre: 'ADA ₳', placeholder: '', as: Input }} />
               <p className={style.fee}>Service fee <span>2.5%</span><br/>
                  You will receive <span>₳ {fee}</span></p>
               <Button type="submit" disabled={isSubmitting}>Mint</Button>
             </Form>
           )}
         </Formik>
        </div>
    </div>
  )
}

export default Create;
