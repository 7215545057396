export const SIGNIN = `query signin($address: String, $payload: String, $signed: String) {
  signin(address: $address, payload: $payload, signed: $signed) {
    id
    address
    username
    bio
    avatar
    email
    twitter
    instagram
    web
    isCreator
    token
    notifications
  }
}`

export const ALIVE = `query alive {
  alive {
    id
    address
    username
    bio
    avatar
    email
    twitter
    instagram
    web
    isCreator
    token
    notifications
  }
}`

export const USER = `query user($username: String) {
  user(username: $username) {
    id
    address
    username
    bio
    avatar
    twitter
    instagram
    web
    isCreator
    relationship
    followers
    following
  }
}`

export const UPDATE_USER = `mutation updateUser($input: UserIn!) {
  updateUser(input: $input) {
    id
    address
    username
    bio
    avatar
    email
    twitter
    instagram
    web
    token
  }
}`

export const ASSET = `query asset($id: String) {
  asset(id: $id) {
    asset
    policy_id
    asset_name
    fingerprint
    quantity
    initial_mint_tx_hash
    metadata
    creator {
      address
      username
      avatar
    }
    owner {
      address
      username
      avatar
    }
    likes
  }
}`

export const ASSETS = `query assets($address: String, $type: AssetType) {
  assets(address: $address, type: $type)
}`

export const FOLLOWERS = `query getFollowers($username: String) {
  getFollowers(username: $username) {
    id
    avatar
    username
    relationship
  }
}`

export const FOLLOWING = `query getFollowing($username: String) {
  getFollowing(username: $username) {
    id
    avatar
    username
    relationship
  }
}`

export const FOLLOW = `mutation follow($id: ID!) {
  follow(id: $id)
}`

export const LIKE = `mutation like($id: ID!) {
  like(id: $id)
}`


export const UPLOAD = `mutation upload($image: Upload, $tokenName: String) {
  upload(image: $image, tokenName:$tokenName)
}
`

export const MINT = `mutation mint($policyId: String, $tokenName: String, $metadata: String, $price: Int) {
  mint(policyId: $policyId, tokenName: $tokenName, metadata: $metadata, price: $price)
}
`

export const BURN = `mutation burn($policyId: String, $tokenName: String) {
  burn(policyId: $policyId, tokenName: $tokenName)
}
`

export const TIP = `mutation tip($benefactor: String, $beneficiary: String, $amount: Int) {
  tip(benefactor: $benefactor, beneficiary: $beneficiary, amount: $amount)
}
`

export const NOTIFICATIONS = `query notifications {
  notifications {
    id
    user
    type
    message
    read
    createdAt
  }
}
`

export const READ = `mutation read($id: ID) {
  read(id: $id)
}
`
