import style from 'notifications/Button.module.css';

import { Blitz } from 'common/Icons';

const Button = (props) => {

  return (
    <div className={style.wrapper}>
      <button {...props}><Blitz/></button>
      { props.notifications > 0 && <div className={style.badge}><span>{props.notifications}</span></div>}
    </div>
  )
}

Button.defaultProps = {
  className: style.button
}

export default Button;
