import Loader from 'utils/loader';

await Loader.load();

const S = Loader.Cardano;

const P = {
  linearFee: S.LinearFee.new(S.BigNum.from_str('44'), S.BigNum.from_str('155381')),
  coinsPerUtxoWord: S.BigNum.from_str('34482'),
  poolDeposit: S.BigNum.from_str('500000000'),
  keyDeposit: S.BigNum.from_str('2000000'),
  maxValueBytes: 5000,
  maxTxBytes: 16384
}

const initTx = _ => {
  const txBuilder = S.TransactionBuilder.new(
    S.TransactionBuilderConfigBuilder.new()
        .fee_algo(P.linearFee)
        .pool_deposit(P.poolDeposit)
        .key_deposit(P.keyDeposit)
        .coins_per_utxo_word(P.coinsPerUtxoWord)
        .max_value_size(P.maxValueBytes)
        .max_tx_size(P.maxTxBytes)
        .build()
  );

  return { txBuilder };
};

export const tipTx = ({ benefactor, beneficiary, amount, utxos }) => {
  const lovelace = Math.floor(amount * 1000000).toString();

  const {txBuilder} = initTx();

  const txUO = S.TransactionUnspentOutputs.new();
  utxos.forEach(u => txUO.add(S.TransactionUnspentOutput.from_bytes(Buffer.from(u, 'hex'))))
  txBuilder.add_inputs_from(txUO ,0);

  txBuilder.add_output(
    S.TransactionOutputBuilder.new()
    .with_address(S.Address.from_bech32(beneficiary))
    .next()
    .with_value(S.Value.new(S.BigNum.from_str(lovelace)))
    .build()
  )

  txBuilder.add_change_if_needed(S.Address.from_bech32(benefactor))

  const transaction = txBuilder.build_tx().to_bytes();

  return Buffer.from(transaction).toString("hex");
}

export const mintTx = ({address, tokenName, policy, metadata, utxos}) => {

  const { txBuilder } = initTx();

  const txUO = S.TransactionUnspentOutputs.new();
  utxos.forEach(u => txUO.add(S.TransactionUnspentOutput.from_bytes(Buffer.from(u, 'hex'))))
  txBuilder.add_inputs_from(txUO ,0);

  const policyScript = S.NativeScript.from_bytes(Buffer.from(policy.script, "hex"));

  txBuilder.add_mint_asset(
    policyScript,
    S.AssetName.new(Buffer.from(tokenName)),
    S.Int.new(S.BigNum.from_str('1'))
  )

  const meta = { [policy.id]: { [tokenName]: metadata } };

  txBuilder.add_json_metadatum_with_schema(
    S.BigNum.from_str('721'),
    JSON.stringify(meta),
    0);

  txBuilder.add_change_if_needed(S.Address.from_bech32(address))

  const transaction = txBuilder.build_tx().to_bytes();

  return Buffer.from(transaction).toString("hex");
}

export const burnTx = ({address, tokenName, policy, utxos}) => {
  const { txBuilder } = initTx();

  const txUO = S.TransactionUnspentOutputs.new();
  utxos.forEach(u => txUO.add(S.TransactionUnspentOutput.from_bytes(Buffer.from(u, 'hex'))))
  txBuilder.add_inputs_from(txUO ,0);

  const policyScript = S.NativeScript.from_bytes(Buffer.from(policy.script, "hex"));

  txBuilder.add_mint_asset(
    policyScript,
    S.AssetName.new(Buffer.from(tokenName, 'hex')),
    S.Int.new_negative(S.BigNum.from_str('1'))
  )

  txBuilder.add_change_if_needed(S.Address.from_bech32(address))

  const transaction = txBuilder.build_tx().to_bytes();

  return Buffer.from(transaction).toString("hex");
}

export const signTx = ({tx, witnesses}) => {
  let transaction = S.Transaction.from_bytes(Buffer.from(tx, "hex"));

  let witness = S.TransactionWitnessSet.from_bytes(Buffer.from(witnesses, "hex"));
  let txWitness = transaction.witness_set();
  txWitness.set_vkeys(witness.vkeys());

  const signedTx = S.Transaction.new(
    transaction.body(),
    txWitness,
    transaction.auxiliary_data()
  ).to_bytes();

  return Buffer.from(signedTx).toString("hex");
}


export const createPolicy = ({address}) => {
  const keyHash = S.BaseAddress
    .from_address(S.Address.from_bech32(address))
    .payment_cred()
    .to_keyhash();

  const script = S.ScriptPubkey.new(keyHash);
  const nativeScript = S.NativeScript.new_script_pubkey(script);
  const policyId = Buffer.from(
    S.ScriptHash.from_bytes(nativeScript.hash().to_bytes()).to_bytes()
    , 'hex').toString('hex');

  return {
    id: policyId,
    script: Buffer.from(nativeScript.to_bytes()).toString("hex"),
  }
}
