import style from 'common/Markdown.module.css';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

const Markdown = () => {
  const [ body, set ] = useState('');
  const { doc } = useParams();

  useEffect(() => {
    const getMD = async doc => {
      const md = await (await fetch(`/docs/${doc}.md`)).text();
      set(md);
    }
    getMD(doc)
  }, [doc])

  return (
    <ReactMarkdown className={style.markdown} children={body} />
  )
}

export default Markdown;
