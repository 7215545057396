import style from 'notifications/Notifications.module.css';

import { useQuery, useMutation } from 'graphql-hooks';
import { useSession } from 'utils/useSession';

import { NOTIFICATIONS, READ } from 'utils/queries';
import { Burn, Bolt, Dollar, LikeFill } from 'common/Icons';

const Notifications = (props) => {

  const { refresh } = useSession();
  const { data, refetch } = useQuery(NOTIFICATIONS);
  const [read] = useMutation(READ);

  if(!data?.notifications) return <div>No notifications...</div>

  const icon = type => {
    if(type === 'BURN') return <Burn/>;
    if(type === 'LIKE') return <LikeFill/>;
    if(type === 'MINT') return <Bolt/>;
    if(type === 'TIP') return <Dollar/>;
    return;
  }

  const markRead = async id => {
    const { data } = await read({ variables: { id } });
    if(data) {
      refetch();
      refresh();
    }
  }

  const Item = ({ id, message, type, read, createdAt }) => {

    const date = new Date(parseInt(createdAt));

    return (<li className={ read ? style.read : '' } onClick={_ => markRead(id)}>
      <span className={style.timestamp}>{date.toLocaleString()}</span>
      { icon(type) }
      <span dangerouslySetInnerHTML={{ __html: message}}/>
      </li>)
  }


  return (
    <div className={style.notifications}>
      <h2>Notifications</h2>
      <ul>
      {
        data?.notifications?.map((n,i) => (
          <Item {...n} key={i}/>
        ))
      }
      </ul>
    </div>
  )
}


export default Notifications;
