import style from 'profile/Avatar.module.css';

import img from 'img/default.svg';

const Avatar = ({cid, isThumb}) => {
  const size = isThumb ? style.small : ''
  const url = cid ?  `${process.env.REACT_APP_IPFS}/${cid}` : img;

  return (
    <div className={size}>
      <div className={style.border}>
        <img className={style.image} src={url} alt="Profile"/>
      </div>
    </div>
  )
}

export default Avatar;
