import style from 'profile/Edit.module.css';

import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";

import { Formik, Form, Field } from 'formik';
import { useDropzone } from 'react-dropzone'

import { useMutation } from 'graphql-hooks';

import { UPDATE_USER } from 'utils/queries';
import { useSession } from 'utils/useSession';
import Input from 'common/Input';
import Button from 'common/Button';
import Textarea from 'common/Textarea';
import {Instagram, Twitter, Web} from 'common/Icons.jsx';
import {isEmail, isTwitter, isInstagram, isNoHttpUrl } from 'utils/validate';

const Edit = _ => {
  const { user, setUser } = useSession();
  const [preview, setPreview] = useState(null);
  const [image, setImage] = useState(null);

  const navigate = useNavigate();
  const [updateUser] = useMutation(UPDATE_USER);

  const onDrop = useCallback(async ([file]) => {
    setImage(file);
    setPreview(URL.createObjectURL(file));
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({ onDrop, accept: 'image/*', maxFiles: 1 });

  useEffect(() => {
    if(user?.avatar) setPreview(`${process.env.REACT_APP_IPFS}/${user.avatar}`);
  }, [user])

  const initialValues = {
    username: user?.username || '',
    bio: user?.bio || '',
    email: user?.email || '',
    web: user?.web || '',
    twitter: user?.twitter || '',
    instagram: user?.instagram || '',
  };

  const validate = values => {
    const errors = {};

    if(!isInstagram.test(values.username)) {
      errors.username = 'Invalid username use only a-Z, 0-9, .-_';
    }
    if(!isEmail.test(values.email) && values.email !== '') {
      errors.email = 'Invalid email address';
    }
    if(!isNoHttpUrl.test(values.web) && values.web !== '') {
      errors.web = 'Invalid url';
    }
    if(!isTwitter.test(values.twitter) && values.twitter !== '') {
      errors.twitter = 'Invalid twitter handle';
    }
    if(!isInstagram.test(values.instagram) && values.instagram !== '') {
      errors.instagram = 'Invalid instagram handle';
    }

    return errors;
  };

  const onSubmit = async values => {
    const input = { ...values, image };
    const result = await updateUser({ variables: { input } });

    if(result.data?.updateUser) {
      setUser({...result.data?.updateUser});

      navigate(`/${user.username || user.id}`);
    }
  };

  return (
    <div className={style.edit}>
      <h1>Edit your profile</h1>
      <p>You can set preferred display name, create your branded profile URL and manage other personal settings</p>
      <div className={style.column}>

        <div {...getRootProps({className: style.dropzone})}>
          <input {...getInputProps()} />
          {
            preview ? <img className={style.preview} src={preview} alt="profile"/> :
            isDragActive ?
              <p>Drop your profile image here ...</p> :
              <p>Drag 'n' drop your profile image here, or click to select image</p>
          }
        </div>

        <Formik {...{ initialValues, validate, onSubmit}} enableReinitialize className={style.form}>
           {({ isSubmitting }) => (
             <Form>
               <Field {...{ name: 'username', type: 'Text', pre: 'glossglaze/', label: 'Username', placeholder: 'Enter username', as: Input }} />
               <Field {...{ name: 'bio', type: 'textarea', label: 'Bio', placeholder: 'Tell the world your story', as: Textarea }} />
               <Field {...{ name: 'email', type: 'email', label: 'Email', placeholder: 'Your email', as: Input }} />
               <Field {...{ name: 'web', type: 'Text', Icon: Web, label: 'Web', placeholder: 'website.com', as: Input }} />
               <Field {...{ name: 'instagram', type: 'Text', Icon: Twitter, label: 'Instagram', placeholder: '@instagram', as: Input }} />
               <Field {...{ name: 'twitter', type: 'Text', Icon: Instagram, label: 'Twitter', placeholder: '@twitter handle', as: Input }} />
               <Button type="submit" disabled={isSubmitting}>Save</Button>
             </Form>
           )}
         </Formik>
        </div>
    </div>
  )
}

export default Edit;
