import style from 'profile/Header.module.css';

import { Link, useNavigate } from "react-router-dom";
import { useMutation } from 'graphql-hooks';
import { FOLLOW } from 'utils/queries';

import {Instagram, Twitter, Web, Badge, Copy} from 'common/Icons.jsx';
import { useSession } from 'utils/useSession';
import Avatar from 'profile/Avatar';
import Button from 'common/Button';
import Tipp from 'profile/Tip';

const ProfileHeader = (data) => {
  let navigate = useNavigate();
  const {user} = useSession();
  const [follow] = useMutation(FOLLOW);

  const buttonLabel = data.relationship;
  const address = data.address;
  const shortAddress = address?.slice(0, 10) + '...' + address?.slice(-10);

  const click = async e => {
      if(e.target.id === 'Edit profile') return navigate(`edit`);
      await follow({ variables: { id: data.id } })
      data.refetch();
      // 'Follow' // 'Unfollow' // 'Follow back' // 'Edit profile' // nothing
  }

  const copy = _ => {
    navigator.clipboard.writeText(address);
  }

  return (
    <div className={style.header}>
      <div className={style.avatar}>
        <Link to={``}><Avatar cid={data.avatar}/></Link>
      </div>
      <div className={style.meta}>
        <div className={style.username}>
          <h1><Link to={``}>{data.username || '..YourName'}</Link></h1>
          { data.isCreator && <Badge/> }
          { buttonLabel && <Button id={buttonLabel} onClick={click}>{buttonLabel}</Button>}
          { user?.address !== data.address && <Tipp {...data}/>}
          <div className={style.address}>{shortAddress}<Copy className={style.copy} onClick={copy}/></div>
        </div>
        <div className={style.stats}>
          <Link to="followers"><strong>{data.followers}</strong> followers</Link>
          <Link to="following"><strong>{data.following}</strong> following</Link>
        </div>
        <div className={style.bio}>{data.bio}</div>
        <div className={style.links}>
          {data.web && <a href={`https://${data.web}`}><Web/><span>{data.web}</span></a>}
          {data.instagram && <a href={`https://www.instagram.com/${data.instagram}`}><Instagram/><span>{data.instagram}</span></a>}
          {data.twitter && <a href={`https://twitter.com/${data.twitter}`}><Twitter/><span>{data.twitter}</span></a>}
        </div>
      </div>
    </div>
  )
}

export default ProfileHeader;
