import style from 'profile/Tip.module.css';

import { Formik, Form, Field } from 'formik';

import { useState } from 'react';
import { useMutation } from 'graphql-hooks';

import { useSession } from 'utils/useSession';
import { tipTx, signTx } from 'utils/cardano';
import { TIP } from 'utils/queries';
import Avatar from 'profile/Avatar';
import Input from 'common/Input';
import Button from 'common/Button';

const Tip = (props) => {
  const [isOpen, setOpen] = useState(false);
  const {user} = useSession();
  const [tip] = useMutation(TIP);

  const toggle = _ => setOpen(prev => !prev);
  const noBubble = e => e.stopPropagation();

  const username = props.username
  const address = props.address;
  const shortAddress = address?.slice(0, 10) + '...' + address?.slice(-10);

  const initialValues = {
    amount: '',
  };

  const validate = values => {
    let errors = {};
    if(values.amount < 1) errors.amount = 'Not min 1 ADA';

    return errors;
  };

  const onSubmit = async values => {
    const { amount } = values;

    const beneficiary = props.address;
    const benefactor = user.address;
    const utxos = await window.cardano.getUtxos();

    const tx = tipTx({benefactor, beneficiary, amount, utxos});
    const witnesses = await window.cardano.signTx(tx);
    const signedTx = await signTx({tx, witnesses});
    const txHash = await window.cardano.submitTx(signedTx);

    await tip({ variables: { benefactor, beneficiary, amount } }).catch(e => console.log(e));;

    console.log('tip tx:', txHash);

    setOpen(false);
  };

  if(!isOpen)
  return <Button onClick={toggle}>Tip ₳</Button>

  if(isOpen)
  return (
    <div className={style.blur} onClick={toggle}>
      <div className={style.modal} onClick={noBubble}>
        <h2>Send tip to</h2>
        <div className={style.user}>
          <Avatar cid={props.avatar} isThumb="true"/>
          <div className={style.username}>
            {username}
            <div className={style.address}>{shortAddress}</div>
          </div>

        </div>
        <Formik {...{ initialValues, validate, onSubmit}} enableReinitialize className={style.form}>
           {({ isSubmitting }) => (
             <Form>
               <Field {...{ name: 'amount', type: 'number', pre: '₳', label: 'Tip amount', placeholder: 'Min 1 ADA', as: Input }} />
               <Button type="button" onClick={toggle}>Cancel</Button>
               <Button type="submit" disabled={isSubmitting}>Send</Button>
             </Form>
           )}
         </Formik>
      </div>
    </div>
  )
}

Tip.defaultProps = {
  className: style.button
}

export default Tip;
