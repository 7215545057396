import { useParams } from 'react-router-dom';
import { useQuery } from 'graphql-hooks';
import { USER } from 'utils/queries';
import { Outlet } from "react-router-dom";

import ProfileHeader from 'profile/Header';

const Profile = _ => {
  const { username } = useParams();

  const { data, refetch } = useQuery(USER, { variables: { username }})
  return (
      <>
        <ProfileHeader {...{ ...data?.user, refetch }} />
        <Outlet context={{...data?.user, refetch}} />
      </>
  )
}

export default Profile;
