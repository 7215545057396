import { useState, useEffect, useContext, createContext } from 'react';
import { useManualQuery, ClientContext } from 'graphql-hooks';
import { ALIVE } from 'utils/queries';

const Session = createContext();
const {Provider} = Session;

const SessionProvider = ({children}) => {
  const [user, setUser] = useState(undefined);
  const client = useContext(ClientContext);
  const [alive] = useManualQuery(ALIVE)

  const localUser = JSON.parse(localStorage.getItem('user') || null);
  if(localUser?.token) {
    client.setHeader('Authorization', `Bearer ${localUser.token}`);
  };

  const isAlive = async _ => {
    const { data } = await alive();
    if(data.alive) return setUser(data.alive);

    localStorage.removeItem('user');
    client.removeHeader('Authorization');
  }

  useEffect(() => {
    if(localUser) isAlive();
  },[])

  useEffect(() => {
    if(user) {
      localStorage.setItem('user', JSON.stringify(user));
      client.setHeader('Authorization', `Bearer ${user.token}`);
    }
  }, [user])

  const refresh = async _ => isAlive();

  return <Provider value={{user, setUser, refresh}}>{children}</Provider>
}

export const useSession = () => useContext(Session);
export default SessionProvider;
