import style from 'common/List.module.css';

import { Link } from "react-router-dom";
import { useOutletContext } from 'react-router-dom';

import Avatar from 'profile/Avatar';
import Button from 'common/Button';

const ListItem = ({ link, thumb, title, text, control }) => {

  // user header
  const { refetch } = useOutletContext();

  const click = async e => {
    e.preventDefault();
      await control.action()
      control.refetch();
      // refetch user header
      refetch();
  }

  return (
    <Link to={`/${link}`} className={style.link}>
      <li className={style.item}>
        { thumb && <Avatar {...{cid: thumb, isThumb: true}} /> }
        <div className={style.text}>
          <div className={style.primary}>{title}</div>
          {text && <div className={style.secondary}>{text}</div>}
        </div>
        <div className={style.control}>
          { control && <Button onClick={click}>{control.label}</Button>}
        </div>
      </li>
    </Link>
  )
}

const List = ({ data = [] }) => {
  return (
    <ul className={style.list}>
      {
        data.map((item, i) => <ListItem {...{...item}} key={i} />)
      }
    </ul>
  )
}

export default List;
