import style from 'common/Input.module.css';

 import { useField } from 'formik';

const Input = ({ Icon, pre, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className={`${style.group} ${meta.error && style.error}`}>
      <label htmlFor={props.name} className={style.label}>{props.label}</label>
        <div className={style.input}>
        { Icon && <Icon className={style.icon}/> }
        { pre && <div className={style.pre}>{pre}</div> }
        <input {...field} {...props} />
        </div>
      <div className={style.errorMsg}>{meta.error}</div>
    </div>
  )
}

export default Input;
