import { useMutation } from 'graphql-hooks';

import { createPolicy, burnTx, signTx } from 'utils/cardano';
import { useSession } from 'utils/useSession';

import { BURN } from 'utils/queries';
import Button from 'common/Button';


const Burn = ({tokenName}) => {
  const [burn] = useMutation(BURN);
  const {user} = useSession();

  const click = async _ => {
    // get utxos
    const utxos = await window.cardano.getUtxos();

    // generate policy
    const { address } = user;
    const policy = createPolicy({address});

    // generate, sign and submit tx
    const tx = burnTx({address, tokenName, policy, utxos});
    const witnesses = await window.cardano.signTx(tx);
    const signedTx = await signTx({tx, witnesses});
    const txHash = await window.cardano.submitTx(signedTx);

    console.log('burn tx:', txHash);

    await burn({ variables: { policyId: policy.id, tokenName } }).catch(e => console.log(e));
  }

  return (
    <Button onClick={click}>Burn</Button>
  )
}

export default Burn;
