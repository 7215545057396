import style from 'profile/Gallery.module.css';

import { useLocation, useOutletContext } from 'react-router-dom';
import { useQuery } from 'graphql-hooks';
import { ASSETS } from 'utils/queries';

import { NavLink } from 'react-router-dom';
import Tab from 'common/Tab';
import Token from 'token/Token';


const Gallery = _ => {
  const { address } = useOutletContext();
  const { pathname } = useLocation();
  const route = pathname.split("/").pop();

  const { data } = useQuery(ASSETS, { variables: { address, type: route.toUpperCase() }})

  const result = _ => {
    const assets = data?.assets || [];
    if(assets?.length === 0) return <p>Nothing there yet!</p>
    return assets.map((a,i) => <Token id={a} key={i}/>);
  }

  return (
    <>
      <Tab>
        <NavLink to="../created">Created</NavLink>
        <NavLink to="../owned">Owned</NavLink>
        <NavLink to="../liked">Liked</NavLink>
      </Tab>

      <div className={style.gallery}>
        { result() }
      </div>
    </>
  )
}

export default Gallery;
