import { useMutation } from 'graphql-hooks';

import { LIKE } from 'utils/queries';
import Button from 'common/Button';
import { Like } from 'common/Icons';


const LikeButton = ({ id, count, refetch }) => {
  const [like] = useMutation(LIKE);

  const click = async _ => {
    await like({ variables: { id } }).catch(e => console.log(e));
    refetch()
  }

  return (
    <Button onClick={click}><Like />{count || 'Like'}</Button>
  )
}

export default LikeButton;
