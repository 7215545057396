import style from 'common/Button.module.css';

const Button = (props) => {
  return (
    <button {...props}>{props.children}</button>
  )
}

Button.defaultProps = {
  className: style.button
}

export default Button;
