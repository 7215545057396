import style from 'common/Header.module.css';

import logo from 'img/glossglaze_logo.svg';

import { Link } from "react-router-dom";
import { useSession } from 'utils/useSession';

import Button from 'common/Button';
import Notifications from 'notifications/Button';
import Auth from 'profile/Auth';

const Header = () => {

  const { user } = useSession();

  return (
    <header className={style.header}>
      <div className={style.bar}>
        <Link className={style.logo} to="/"><img src={logo} alt="logo"/></Link>
        { user?.isCreator && <Link to="token/create"><Button>Create</Button></Link> }
        { user && <Link to={`/notifications`}><Notifications notifications={user?.notifications || 0}/></Link> }

        <Auth>Sign in</Auth>
      </div>
    </header>
  )
}

export default Header;

/*
<header className={style.header}>
  <img className={style.logo} src={logo} alt="logo"/>
  <form className={style.search}>
    <SearchIcon/>
    <input type="text" placeholder="Search items, collections, users"/>
    <button>
      <CloseIcon/>
    </button>
  </form>
  <div className={style.spacer}/>
  <div className={`${style.icon} ${style.notifications}`}/>
  <div className={`${style.icon} ${style.profile}`}/>
</header>
*/
