import style from 'token/TokenDetail.module.css';

import { useParams } from 'react-router-dom';
import { useQuery } from 'graphql-hooks';

import { ASSET } from 'utils/queries';
import Burn from 'token/Burn';
import Like from 'token/Like';

const Token = _ => {
  const { token: id } = useParams();

  const { data, refetch } = useQuery(ASSET, { variables: { id }})
  const { asset } = data || {};

  const meta = JSON.parse(asset?.metadata || "[]");
  const url = `${process.env.REACT_APP_IPFS}/${meta?.image?.replace('ipfs://','')}`;

  console.log(asset);

  return (
    <div className={style.asset}>
      <img className={style.image} src={url} alt={meta.name}/>
      <div className={style.details}>
        <div className={style.header}>
          <h1>{meta.name}</h1>
          <Like id={asset?.asset} count={asset?.likes} refetch={refetch}/>
        </div>
        <div className={style.description}>{meta.description}</div>
        <p>{meta.creator}</p>
        <p>royalties</p>
        <p>{meta.photographer}</p>
        <div>
        <span>Owner</span>
        <span>Shalala</span>
        </div>
        <Burn tokenName={asset?.asset_name}/>

      </div>
    </div>
  )
}

export default Token;
