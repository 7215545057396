import style from 'profile/Auth.module.css';

import { Link } from "react-router-dom";
import { useManualQuery } from 'graphql-hooks';
import { Buffer } from 'buffer';

import { useSession } from 'utils/useSession';
import { SIGNIN } from 'utils/queries';
import Button from 'common/Button';
import {Profile, Bolt, Off} from 'common/Icons';

import Avatar from 'profile/Avatar';

const Auth = ({children, ...props}) => {

  const [signIn] = useManualQuery(SIGNIN);
  const { user, setUser } = useSession();

  const click = async _ => {
    const cardano = window.cardano;
    await cardano.enable();

    const payload = 'Gloss & Glaze : Sign to Login.';

    const [address] = await cardano.getUsedAddresses();
    const signed = await cardano.signData(address, Buffer.from(payload).toString('hex'));

    const result = await signIn({ variables: { address, payload, signed } });

    if(result.data?.signin) {
      const data = result.data.signin;
      setUser(data);
    }
  }
/*
  const render = _ => {
    const cardano = window.cardano;
    // find wallets
    const wallets = [];
    for(const key in cardano) {
      if(cardano[key].apiVersion) wallets.push(cardano[key]);
    }

    return wallets.map((w,i) => <div className={style.item} key={i}><img src={w.icon}/>{w.name}</div>)
  }

  const click = async _ => {
    const cardano = window.cardano;
    // find wallets
    const wallets = [];
    for(const key in cardano) {
      if(cardano[key].apiVersion) wallets.push(cardano[key]);
    }

  }

  const logout = _ => {
    setUser(null);
    localStorage.removeItem('user');
  }

  if(user?.username || user?.id) return (
    <div className={style.action}>
        <Avatar cid={user.avatar} isThumb={true}/>
      <div className={style.dropdown}>
        <div className={style.content}>
          <Link to={user.username || user.id} className={style.item}><Profile/>Profile</Link>
          <Link to={`${user.username || user.id}/edit`} className={style.item}><Bolt/>Edit profile</Link>
          <div className={style.item} onClick={logout}><Off/>Logout</div>
        </div>
      </div>
    </div>
  )
*/
  return (
    <div className={style.action}>
      {
        // Avatar (logged in)
        // Sign In (button)
      }
        <Button onClick={click}>{children}</Button>
      <div className={style.dropdown}>
        <div className={style.content}>
          {
            // Profile, Edit, Logout
            // Wallet 1, Wallet 2, ...
          }
        </div>
      </div>
    </div>
  )
  return <Button onClick={click}>{children}</Button>
}

export default Auth;


/*

import style from 'profile/Auth.module.css';

import { Link } from "react-router-dom";
import { useManualQuery } from 'graphql-hooks';
import { Buffer } from 'buffer';

import { useSession } from 'utils/useSession';
import { SIGNIN } from 'utils/queries';
import Button from 'common/Button';
import {Profile, Bolt, Off} from 'common/Icons';

import Avatar from 'profile/Avatar';

const Auth = ({children, ...props}) => {

  const [signIn] = useManualQuery(SIGNIN);
  const { user, setUser } = useSession();

  const click = async _ => {
    const cardano = window.cardano;
    await cardano.enable();

    const payload = 'Gloss & Glaze : Sign to Login.';

    const [address] = await cardano.getUsedAddresses();
    const signed = await cardano.signData(address, Buffer.from(payload).toString('hex'));

    const result = await signIn({ variables: { address, payload, signed } });

    if(result.data?.signin) {
      const data = result.data.signin;
      setUser(data);
    }
  }

  const logout = _ => {
    setUser(null);
    localStorage.removeItem('user');
  }

  if(user?.username || user?.id) return (
    <div className={style.action}>
        <Avatar cid={user.avatar} isThumb={true}/>
      <div className={style.dropdown}>
        <div className={style.content}>
          <Link to={user.username || user.id} className={style.item}><Profile/>Profile</Link>
          <Link to={`${user.username || user.id}/edit`} className={style.item}><Bolt/>Edit profile</Link>
          <div className={style.item} onClick={logout}><Off/>Logout</div>
        </div>
      </div>
    </div>
  )

  return <Button onClick={click}>{children}</Button>
}

export default Auth;
*/
