import style from 'common/Footer.module.css';

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className={style.footer}>
      <div className={style.copyright}>
        <span>© Gloss & Glaze, Inc. All rights reserved.</span>
      </div>
      <div className={style.links}>
        <Link to="s/about">About</Link>
        <Link to="s/help">Help</Link>
        <Link to="s/terms">Terms</Link>
        <Link to="s/privacy">Privacy</Link>
      </div>
    </div>
  )
}

export default Footer;
