import style from 'common/Textarea.module.css';

import { useState, useEffect, useRef } from 'react';
import { useField } from 'formik';

const Textarea = props => {
  const area = useRef(null);
  const [height, set] = useState(20);
  const [field, meta] = useField(props);

  useEffect(() => {
      set(area.current.scrollHeight-4)
  }, [props.value])

  const change = e => {
    set(area.current.scrollHeight-4);
    field.onChange(e)
  }
  return (
    <div className={`${style.group} ${meta.error && style.error}`}>
      <div className={style.label}>{props.label}</div>
      <div className={style.textarea}>
        <textarea style={{height}} ref={area} {...field} {...props} onChange={change} />
      </div>
      <div className={style.errorMsg}>{meta.error}</div>
    </div>
  )
}

export default Textarea;
