import { GraphQLClient, ClientContext } from 'graphql-hooks';
import {Routes, Route, Navigate} from 'react-router-dom';
import './App.css';

import Session from 'utils/useSession';

import Header from 'common/Header';
import Footer from 'common/Footer';

import Profile from 'profile/Profile';
import Edit from 'profile/Edit';
import Gallery from 'profile/Gallery';
import Follow from 'profile/Follow';
import Create from 'token/Create';
import Markdown from 'common/Markdown';
import Notifications from 'notifications/Notifications';
import Token from 'token/TokenDetail';
import Landing from 'Landing';


const client = new GraphQLClient({
  url: '/graphql',
  logErrors: false,
})

const App = _ => {
  return (
    <ClientContext.Provider value={client}>
      <Session>
        <Header/>
        <main>
        <Routes>
          <Route path="/" element={<Landing/>}/>
          <Route path=":username" element={<Profile/>}>
            <Route path="followers" element={<Follow/>}/>
            <Route path="following" element={<Follow/>}/>
            <Route path="created" element={<Gallery/>}/>
            <Route path="owned" element={<Gallery/>}/>
            <Route path="liked" element={<Gallery/>}/>

            <Route index element={<Navigate to="created" replace/>}/>
          </Route>
          <Route path="notifications" element={<Notifications/>}/>

          <Route path=":username/edit" element={<Edit/>}/>
          <Route path="token/create" element={<Create/>}/>
          <Route path="token/:token" element={<Token/>}/>
          <Route path="s/:doc" element={<Markdown/>}/>
        </Routes>
        </main>
        <Footer/>
      </Session>
    </ClientContext.Provider>
  );
}

export default App;
